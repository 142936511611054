import { LandingLayoutComponent } from './shared/components/layouts/landing-layout/landing-layout.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
    {
        path: '',
        component: LandingLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: './views/landing/landing.module#LandingModule'
            },
            {
                path: 'papers',
                loadChildren: './views/papers/papers.module#PapersModule'
            }
        ]
    },
    {
        path: 'pricing',
        loadChildren: './views/pricing/pricing.module#PricingModule'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true, enableTracing: true })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
